// @ts-check
import { initSchema } from '@aws-amplify/datastore';
import { schema } from './schema';



const { DashboardWidget, Survey, SurveyUser, User, SurveySection, SurveyResult, SurveyResultArchive, SurveyAnalysisConfiguration, SurveyAnalysisResult, SurveyExport } = initSchema(schema);

export {
  DashboardWidget,
  Survey,
  SurveyUser,
  User,
  SurveySection,
  SurveyResult,
  SurveyResultArchive,
  SurveyAnalysisConfiguration,
  SurveyAnalysisResult,
  SurveyExport
};