/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onCreateDashboardWidget = /* GraphQL */ `
  subscription OnCreateDashboardWidget {
    onCreateDashboardWidget {
      id
      survey_id
      name
      field
      group_by
      value
      legend
      y_axis_title
      component_type
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      survey {
        id
        survey_content
        survey_name
        sectional
        identifiers
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
    }
  }
`;
export const onUpdateDashboardWidget = /* GraphQL */ `
  subscription OnUpdateDashboardWidget {
    onUpdateDashboardWidget {
      id
      survey_id
      name
      field
      group_by
      value
      legend
      y_axis_title
      component_type
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      survey {
        id
        survey_content
        survey_name
        sectional
        identifiers
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
    }
  }
`;
export const onDeleteDashboardWidget = /* GraphQL */ `
  subscription OnDeleteDashboardWidget {
    onDeleteDashboardWidget {
      id
      survey_id
      name
      field
      group_by
      value
      legend
      y_axis_title
      component_type
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      survey {
        id
        survey_content
        survey_name
        sectional
        identifiers
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
    }
  }
`;
export const onCreateSurveyUser = /* GraphQL */ `
  subscription OnCreateSurveyUser {
    onCreateSurveyUser {
      id
      restricted_field
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      survey {
        id
        survey_content
        survey_name
        sectional
        identifiers
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      user {
        id
        username
        email
        avatarKey
        description
        owner
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
    }
  }
`;
export const onUpdateSurveyUser = /* GraphQL */ `
  subscription OnUpdateSurveyUser {
    onUpdateSurveyUser {
      id
      restricted_field
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      survey {
        id
        survey_content
        survey_name
        sectional
        identifiers
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      user {
        id
        username
        email
        avatarKey
        description
        owner
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
    }
  }
`;
export const onDeleteSurveyUser = /* GraphQL */ `
  subscription OnDeleteSurveyUser {
    onDeleteSurveyUser {
      id
      restricted_field
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      survey {
        id
        survey_content
        survey_name
        sectional
        identifiers
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      user {
        id
        username
        email
        avatarKey
        description
        owner
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
    }
  }
`;
export const onCreateSurvey = /* GraphQL */ `
  subscription OnCreateSurvey {
    onCreateSurvey {
      id
      survey_content
      survey_name
      sectional
      identifiers
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateSurvey = /* GraphQL */ `
  subscription OnUpdateSurvey {
    onUpdateSurvey {
      id
      survey_content
      survey_name
      sectional
      identifiers
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteSurvey = /* GraphQL */ `
  subscription OnDeleteSurvey {
    onDeleteSurvey {
      id
      survey_content
      survey_name
      sectional
      identifiers
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const onCreateSurveySection = /* GraphQL */ `
  subscription OnCreateSurveySection {
    onCreateSurveySection {
      id
      survey_id
      section_id
      section_content
      ordering
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      survey {
        id
        survey_content
        survey_name
        sectional
        identifiers
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
    }
  }
`;
export const onUpdateSurveySection = /* GraphQL */ `
  subscription OnUpdateSurveySection {
    onUpdateSurveySection {
      id
      survey_id
      section_id
      section_content
      ordering
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      survey {
        id
        survey_content
        survey_name
        sectional
        identifiers
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
    }
  }
`;
export const onDeleteSurveySection = /* GraphQL */ `
  subscription OnDeleteSurveySection {
    onDeleteSurveySection {
      id
      survey_id
      section_id
      section_content
      ordering
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      survey {
        id
        survey_content
        survey_name
        sectional
        identifiers
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
    }
  }
`;
export const onCreateSurveyResult = /* GraphQL */ `
  subscription OnCreateSurveyResult($owner: String) {
    onCreateSurveyResult(owner: $owner) {
      owner
      id
      survey_result
      mode
      survey_detail
      district
      divisional_secretariat
      gn_division
      district_
      divisional_secretariat_
      gn_division_
      updated_at
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      survey {
        id
        survey_content
        survey_name
        sectional
        identifiers
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      user {
        id
        username
        email
        avatarKey
        description
        owner
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
    }
  }
`;
export const onUpdateSurveyResult = /* GraphQL */ `
  subscription OnUpdateSurveyResult($owner: String) {
    onUpdateSurveyResult(owner: $owner) {
      owner
      id
      survey_result
      mode
      survey_detail
      district
      divisional_secretariat
      gn_division
      district_
      divisional_secretariat_
      gn_division_
      updated_at
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      survey {
        id
        survey_content
        survey_name
        sectional
        identifiers
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      user {
        id
        username
        email
        avatarKey
        description
        owner
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
    }
  }
`;
export const onDeleteSurveyResult = /* GraphQL */ `
  subscription OnDeleteSurveyResult($owner: String) {
    onDeleteSurveyResult(owner: $owner) {
      owner
      id
      survey_result
      mode
      survey_detail
      district
      divisional_secretariat
      gn_division
      district_
      divisional_secretariat_
      gn_division_
      updated_at
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      survey {
        id
        survey_content
        survey_name
        sectional
        identifiers
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      user {
        id
        username
        email
        avatarKey
        description
        owner
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
    }
  }
`;
export const onCreateUser = /* GraphQL */ `
  subscription OnCreateUser($owner: String) {
    onCreateUser(owner: $owner) {
      id
      username
      email
      avatarKey
      description
      owner
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateUser = /* GraphQL */ `
  subscription OnUpdateUser($owner: String) {
    onUpdateUser(owner: $owner) {
      id
      username
      email
      avatarKey
      description
      owner
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteUser = /* GraphQL */ `
  subscription OnDeleteUser($owner: String) {
    onDeleteUser(owner: $owner) {
      id
      username
      email
      avatarKey
      description
      owner
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const onCreateSurveyResultArchive = /* GraphQL */ `
  subscription OnCreateSurveyResultArchive {
    onCreateSurveyResultArchive {
      id
      survey_id
      survey_result_id
      district
      divisional_secretariat
      gn_division
      district_
      divisional_secretariat_
      gn_division_
      submittedAt
      completedAt
      createdAt
      updatedAt
      surveyResultArchiveUserId
      mode
      owner
      your_house
      sanitation_facility
      electricity_in_your_house
      primary_normal_season
      employment_primary_job
      are_you_a_samurdhi_beneficiary
      younger_fifteen_year_dependents
      disabled_dependents
      elder_dependents
      are_you_receiving_public_monthly_assistance_currently
      highest_education_head_household
      value_of_the_samurdhi_stamp
      family_members_receiving_family_public_assistance_total
      family_public_assistance_elders_assistance
      family_public_assistance_disability_assistance
      family_public_assistance_assistance_disease
      family_public_assistance_kidney
      family_public_assistance_tb
      family_public_assistance_leprosy
      family_public_assistance_cancer
      family_public_assistance_spinal_code_injury
      total_income_family
      age_head_household
      no_of_dependent
      sex_of_head_household
      widowed_reason
      do_you_have_own_land
      do_you_have_own_house
      head_household_disabled
      head_of_household
      interest_self_employment
      access_to_well
      count
      _version
      _deleted
      _lastChangedAt
      user {
        id
        username
        email
        avatarKey
        description
        owner
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
    }
  }
`;
export const onUpdateSurveyResultArchive = /* GraphQL */ `
  subscription OnUpdateSurveyResultArchive {
    onUpdateSurveyResultArchive {
      id
      survey_id
      survey_result_id
      district
      divisional_secretariat
      gn_division
      district_
      divisional_secretariat_
      gn_division_
      submittedAt
      completedAt
      createdAt
      updatedAt
      surveyResultArchiveUserId
      mode
      owner
      your_house
      sanitation_facility
      electricity_in_your_house
      primary_normal_season
      employment_primary_job
      are_you_a_samurdhi_beneficiary
      younger_fifteen_year_dependents
      disabled_dependents
      elder_dependents
      are_you_receiving_public_monthly_assistance_currently
      highest_education_head_household
      value_of_the_samurdhi_stamp
      family_members_receiving_family_public_assistance_total
      family_public_assistance_elders_assistance
      family_public_assistance_disability_assistance
      family_public_assistance_assistance_disease
      family_public_assistance_kidney
      family_public_assistance_tb
      family_public_assistance_leprosy
      family_public_assistance_cancer
      family_public_assistance_spinal_code_injury
      total_income_family
      age_head_household
      no_of_dependent
      sex_of_head_household
      widowed_reason
      do_you_have_own_land
      do_you_have_own_house
      head_household_disabled
      head_of_household
      interest_self_employment
      access_to_well
      count
      _version
      _deleted
      _lastChangedAt
      user {
        id
        username
        email
        avatarKey
        description
        owner
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
    }
  }
`;
export const onDeleteSurveyResultArchive = /* GraphQL */ `
  subscription OnDeleteSurveyResultArchive {
    onDeleteSurveyResultArchive {
      id
      survey_id
      survey_result_id
      district
      divisional_secretariat
      gn_division
      district_
      divisional_secretariat_
      gn_division_
      submittedAt
      completedAt
      createdAt
      updatedAt
      surveyResultArchiveUserId
      mode
      owner
      your_house
      sanitation_facility
      electricity_in_your_house
      primary_normal_season
      employment_primary_job
      are_you_a_samurdhi_beneficiary
      younger_fifteen_year_dependents
      disabled_dependents
      elder_dependents
      are_you_receiving_public_monthly_assistance_currently
      highest_education_head_household
      value_of_the_samurdhi_stamp
      family_members_receiving_family_public_assistance_total
      family_public_assistance_elders_assistance
      family_public_assistance_disability_assistance
      family_public_assistance_assistance_disease
      family_public_assistance_kidney
      family_public_assistance_tb
      family_public_assistance_leprosy
      family_public_assistance_cancer
      family_public_assistance_spinal_code_injury
      total_income_family
      age_head_household
      no_of_dependent
      sex_of_head_household
      widowed_reason
      do_you_have_own_land
      do_you_have_own_house
      head_household_disabled
      head_of_household
      interest_self_employment
      access_to_well
      count
      _version
      _deleted
      _lastChangedAt
      user {
        id
        username
        email
        avatarKey
        description
        owner
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
    }
  }
`;
export const onCreateSurveyAnalysisConfiguration = /* GraphQL */ `
  subscription OnCreateSurveyAnalysisConfiguration {
    onCreateSurveyAnalysisConfiguration {
      id
      survey_id
      metric_name
      title
      execution_frequency
      group_by
      measured_field
      measured_value
      measured_against_field
      measured_against_value
      measured_against_fields
      measured_against_values
      multi_field_mode
      measurement_type
      next_update
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateSurveyAnalysisConfiguration = /* GraphQL */ `
  subscription OnUpdateSurveyAnalysisConfiguration {
    onUpdateSurveyAnalysisConfiguration {
      id
      survey_id
      metric_name
      title
      execution_frequency
      group_by
      measured_field
      measured_value
      measured_against_field
      measured_against_value
      measured_against_fields
      measured_against_values
      multi_field_mode
      measurement_type
      next_update
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteSurveyAnalysisConfiguration = /* GraphQL */ `
  subscription OnDeleteSurveyAnalysisConfiguration {
    onDeleteSurveyAnalysisConfiguration {
      id
      survey_id
      metric_name
      title
      execution_frequency
      group_by
      measured_field
      measured_value
      measured_against_field
      measured_against_value
      measured_against_fields
      measured_against_values
      multi_field_mode
      measurement_type
      next_update
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const onCreateSurveyAnalysisResult = /* GraphQL */ `
  subscription OnCreateSurveyAnalysisResult {
    onCreateSurveyAnalysisResult {
      id
      survey_id
      metric_name
      title
      district
      divisional_secretariat
      gn_division
      district_
      divisional_secretariat_
      gn_division_
      execution_frequency
      group_by
      measured_field
      measured_value
      measured_against_field
      measured_against_value
      measured_against_fields
      measured_against_values
      multi_field_mode
      measurement_type
      next_update
      final_value
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onUpdateSurveyAnalysisResult = /* GraphQL */ `
  subscription OnUpdateSurveyAnalysisResult {
    onUpdateSurveyAnalysisResult {
      id
      survey_id
      metric_name
      title
      district
      divisional_secretariat
      gn_division
      district_
      divisional_secretariat_
      gn_division_
      execution_frequency
      group_by
      measured_field
      measured_value
      measured_against_field
      measured_against_value
      measured_against_fields
      measured_against_values
      multi_field_mode
      measurement_type
      next_update
      final_value
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onDeleteSurveyAnalysisResult = /* GraphQL */ `
  subscription OnDeleteSurveyAnalysisResult {
    onDeleteSurveyAnalysisResult {
      id
      survey_id
      metric_name
      title
      district
      divisional_secretariat
      gn_division
      district_
      divisional_secretariat_
      gn_division_
      execution_frequency
      group_by
      measured_field
      measured_value
      measured_against_field
      measured_against_value
      measured_against_fields
      measured_against_values
      multi_field_mode
      measurement_type
      next_update
      final_value
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onCreateSurveyExport = /* GraphQL */ `
  subscription OnCreateSurveyExport {
    onCreateSurveyExport {
      id
      survey_id
      export_id
      exported_at
      initial_date
      last_date
      custom_filter_index
      custom_filter_field
      custom_filter_value
      s3_link
      status
      filters
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateSurveyExport = /* GraphQL */ `
  subscription OnUpdateSurveyExport {
    onUpdateSurveyExport {
      id
      survey_id
      export_id
      exported_at
      initial_date
      last_date
      custom_filter_index
      custom_filter_field
      custom_filter_value
      s3_link
      status
      filters
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteSurveyExport = /* GraphQL */ `
  subscription OnDeleteSurveyExport {
    onDeleteSurveyExport {
      id
      survey_id
      export_id
      exported_at
      initial_date
      last_date
      custom_filter_index
      custom_filter_field
      custom_filter_value
      s3_link
      status
      filters
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
